<!-- 监控详情 -->
<template>
  <div class="appVideo">
    <div class="appflex">
      <div class="divVideoA">
        <div v-if="this.videoList0!=undefined" style="width: 750px;height: 400px;position: relative">
          <div class="video_name">{{videoList0.videoName}}</div>
          <div id="videoList0" style="width: 100%;height: 100%; "></div>
          <BtnVideo class="icoVideo" v-if="videoList0.videoType === 0"
                    :accessToken="videoList0.accessToken"
                    :channelNo="videoList0.channelNo" :deviceSerial="videoList0.videoModel"/>
        </div>
        <div v-if="this.videoList1!=undefined" style="width: 750px;height: 400px;position: relative">
          <div class="video_name">{{videoList1.videoName}}</div>
          <div id="videoList1" style="width: 100%;height: 100%;"></div>
          <BtnVideo1 class="icoVideo" v-if="videoList1.videoType === 0" :accessToken="videoList1.accessToken"
                     :channelNo="videoList1.channelNo" :deviceSerial="videoList1.videoModel"/>
        </div>


        <div v-if="this.videoList2!=undefined" style="width: 750px;height: 400px; position: relative;top:20px">
          <div class="video_name">{{videoList2.videoName}}</div>
          <div id="videoList2" style="width: 100%;height: 100%"></div>
          <BtnVideo2 class="icoVideo" v-if="videoList2.videoType === 0" :accessToken="videoList2.accessToken"
                     :channelNo="videoList2.channelNo" :deviceSerial="videoList2.videoModel"/>
        </div>


        <div v-if="this.videoList3!=undefined" style="width: 750px;height: 400px;position: relative;top:20px">
          <div class="video_name">{{videoList3.videoName}}</div>
          <div id="videoList3" style="width: 100%;height: 100%;position: relative"></div>
          <BtnVideo3 class="icoVideo" v-if="videoList3.videoType === 0"
                     :accessToken="videoList3.accessToken"
                     :channelNo="videoList3.channelNo" :deviceSerial="videoList3.videoModel"/>
        </div>


        <div v-if="this.videoList4!=undefined" style="width: 750px;height: 400px;position: relative;top:20px">
          <div class="video_name">{{videoList4.videoName}}</div>
          <div id="videoList4" style="width: 100%;height: 100%"></div>
          <BtnVideo4 class="icoVideo" v-if="videoList4.videoType === 0" :accessToken="videoList4.accessToken"
                     :channelNo="videoList4.channelNo" :deviceSerial="videoList4.videoModel"/>
        </div>


        <div v-if="this.videoList5!=undefined" style="width: 750px;height: 400px;position: relative;top:20px">
          <div class="video_name">{{videoList5.videoName}}</div>
          <div id="videoList4"></div>
          <BtnVideo5 class="icoVideo" v-if="videoList5.videoType === 0" :accessToken="videoList5.accessToken"
                     :channelNo="videoList5.channelNo" :deviceSerial="videoList5.videoModel"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BtnVideo from '../../components/BtnVideo/index.vue'
import BtnVideo1 from '../../components/BtnVideo1/index.vue'
import BtnVideo2 from '../../components/BtnVideo2/index.vue'
import BtnVideo3 from '../../components/BtnVideo3/index.vue'
import BtnVideo4 from '../../components/BtnVideo4/index.vue'
import BtnVideo5 from '../../components/BtnVideo5/index.vue'
import { login } from '../../api/login'

export default {
  name: 'videodetailslist',
  components: {
    BtnVideo,
    BtnVideo1,
    BtnVideo2,
    BtnVideo3,
    BtnVideo4,
    BtnVideo5,
  },
  data () {
    return {
      siteId: null,// 站点id
      videoList: {}, // 视频列表
      playerInstance: null,
      playerInstance1: null,
      playerInstance2: null,
      playerInstance3: null,
      playerInstance4: null,
      playerInstance5: null,
      playerInstance6: null,
      videoList0: null,
      videoList1: null,
      videoList2: null,
      videoList3: null,
      videoList4: null,
      videoList5: null,
      lengthList: null,
    }
  },
  created () {
    this.siteId = this.$route.params.siteId
  },
  mounted () {
    this.getVideo()
  },
  methods: {
    getVideo () {
      let siteId = this.siteId
      const video0 = document.querySelector('#videoList0')
      const video1 = document.querySelector('#videoList1')
      const video2 = document.querySelector('#videoList2')
      const video3 = document.querySelector('#videoList3')
      const video4 = document.querySelector('#videoList4')
      const video5 = document.querySelector('#videoList5')
      this.$get(`/video/findList?siteId=${siteId}`).then((res) => {
        if (res.code == 200) {
          this.videoList0 = res.data[0]
          this.videoList1 = res.data[1]
          this.videoList2 = res.data[2]
          this.videoList3 = res.data[3]
          this.videoList4 = res.data[4]
          this.videoList5 = res.data[5]

          setTimeout(() => {
            const video0 = document.querySelector('#videoList0')
            const video1 = document.querySelector('#videoList1')
            const video2 = document.querySelector('#videoList2')
            const video3 = document.querySelector('#videoList3')
            const video4 = document.querySelector('#videoList4')
            const video5 = document.querySelector('#videoList5')

            if (this.videoList0 !== null) {
              video0.innerHTML = ''
              this.playerInstance = new this.$ezuikit.EZUIKitPlayer({
                autoplay: true,
                id: 'videoList0',
                header: ['capturePicture', 'save'],
                footer: ['hd', 'fullScreen'],
                accessToken: this.videoList0.accessToken,
                url: this.videoList0.videoAddress,
                template: 'simple',
                audio: 0,
              })
            }

            video1.innerHTML = ''
            this.playerInstance1 = new this.$ezuikit.EZUIKitPlayer({
              autoplay: true,
              id: 'videoList1',
              header: ['capturePicture', 'save'],
              footer: ['hd', 'fullScreen'],
              accessToken: this.videoList1.accessToken,
              url: this.videoList1.videoAddress,
              template: 'simple',
              audio: 0,
            })

            video2.innerHTML = ''
            this.playerInstance2 = new this.$ezuikit.EZUIKitPlayer({
              autoplay: true,
              id: 'videoList2',
              header: ['capturePicture', 'save'],
              footer: ['hd', 'fullScreen'],
              accessToken: this.videoList2.accessToken,
              url: this.videoList2.videoAddress,
              template: 'simple',
              audio: 0,
            })

            video3.innerHTML = ''
            this.playerInstance3 = new this.$ezuikit.EZUIKitPlayer({
              autoplay: true,
              id: 'videoList3',
              header: ['capturePicture', 'save'],
              footer: ['hd', 'fullScreen'],
              accessToken: this.videoList3.accessToken,
              url: this.videoList3.videoAddress,
              template: 'simple',
              audio: 0,
            })

            video4.innerHTML = ''
            this.playerInstance4 = new this.$ezuikit.EZUIKitPlayer({
              autoplay: true,
              id: 'videoList4',
              header: ['capturePicture', 'save'],
              footer: ['hd', 'fullScreen'],
              accessToken: this.videoList4.accessToken,
              url: this.videoList4.videoAddress,
              template: 'simple',
              audio: 0,
            })

            video5.innerHTML = ''
            this.playerInstance4 = new this.$ezuikit.EZUIKitPlayer({
              autoplay: true,
              id: 'videoList5',
              header: ['capturePicture', 'save'],
              footer: ['hd', 'fullScreen'],
              accessToken: this.videoList5.accessToken,
              url: this.videoList5.videoAddress,
              template: 'simple',
              audio: 0,
            })

          }, 1000)

        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.appVideo {
  width: 100%;
  height: 98%;
  color: black;

  .appflex {

    .divVideoA {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      text-align: justify;

      &:after {
        content: "";
        width: 30%;
        height: 10px;
        margin-left: 0px;
        margin-right: 290px;
      }
    }
  }

.video_name{
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 9;
  color: #ffffff;
  font-size: 22px;
}
::v-deep .btn-switch{
  bottom: 8px;
  right: 110px;
}
::v-deep .btn-control{
  bottom: 48px;
  right: 0px;
}
}
</style>
